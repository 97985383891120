import React from 'react';
import Layout from '@components/domain/shared/layout';
import SEO from '@components/ui/seo';
import BackgroundImage from 'gatsby-background-image';
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import EmptySpace from '@components/ui/empty-space';
import ActionLink from '@components/ui/action-link';
import styles from '@styles/pages/404.module.scss';

const getImageNodeByName = (name, images) => {
  const edge = images.edges.find(edge => String(edge.node.name).includes(name));
  if (edge) {
    return edge.node;
  }

  return null;
};

const NotFoundPage = (props) => (
  <Layout isFooterHidden={true}>
    <SEO
      title="404"
      description="Página não encontrada"
    />
    <BackgroundImage
      style={{ position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, zIndex: -100 }}
      fluid={getImageNodeByName('background', props.data.images).childImageSharp.fluid}
    />
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Ups… Cálculo errado!</h1>
      </div>
      <div className={styles.message}>
        <p>
          A página que procuras pode ter sido removida, alterada, estar indisponível ou pode 
          até nunca ter existido. Lamentamos qualquer incómodo causado.
        </p>
      </div>
      <div className={styles.action}>
      <ActionLink path="/" label="voltar à homepage" width="255px" type="dark" />
      </div>
    </div>
    <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
  </Layout>
);

export const query = graphql`
  query NotFoundPageQuery {
    images: allFile(filter: {relativeDirectory: {eq: "domain/404"}}) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  })
};
