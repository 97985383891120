import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth0 } from "@auth0/auth0-react";
import {HttpClient} from "@utilities/http-client";
import {NotAllowedError} from "@components/domain/form/submission";
import NotFoundPage from "@pages/404";
import CursoDetalhesView from "../../../../views/curso-detalhes";
import {graphql} from "gatsby";
import Spinner from "@components/ui/spinner";

const CursoDetalhesPreview = (props) => {
    const {course_slug, iteration_name} = props.params;
    const [ course, setCourse ] = useState();
    const [ error, setError ] = useState(0);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const httpClient = HttpClient(getAccessTokenSilently);

    // load course
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        (async () => {
            const response = await httpClient(`iterations/${course_slug}/${iteration_name}`);
            if (response.ok) {
                const course_id = (await response.json()).id;
                const courseResponse = await httpClient(`iterations/gatsby/${course_id}`);
                if (response.ok)
                    setCourse(await courseResponse.json());
                else
                    setError(403);
            } else
                setError(404);
        })();
    }, [isAuthenticated]);

    return (
        <>
        {error === 404 &&
            <NotFoundPage
                data={props.data}
            />
        }
        {error === 403 &&
            <NotAllowedError
                error="Erro ao carregar a informação pedida."
                errorDescription="Não estás autorizado a ver o conteúdo desta página."
            />
        }
        {error === 0 && course &&
            <CursoDetalhesView
                course={course}
                notice="Esta página é uma pré-visualização, pelo que o seu conteúdo poderá estar diferente do site real. Como não está otimizada, as imagens também poderão demorar mais tempo a carregar."
            />
        }
        {error === 0 && !course &&
            <Spinner />
        }
        </>
    );
};

export const pageQuery = graphql`
  query PreviewPageQuery {
    images: allFile(filter: {relativeDirectory: {eq: "domain/404"}}) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
 `;

export default withAuthenticationRequired(CursoDetalhesPreview);

CursoDetalhesPreview.propTypes = NotFoundPage.propTypes;