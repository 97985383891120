import React from 'react';
import PropTypes from 'prop-types';
import EmptySpace from '@components/ui/empty-space';
import Spinner from '@components/ui/spinner';
import styles from './submission.module.scss';

// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

export const NotAllowedError = ({error, errorDescription}) => {
  return (
    <div className={styles.container}>
      <div className={styles.primary}>
        <FontAwesomeIcon icon={faTimes} className={styles.error}/>
        <span>{error}</span>
      </div>
      <div className={styles.secondary}>{errorDescription}</div>
      <EmptySpace desktop={{ margin: 60, isLineVisible: false }} mobile={{ margin: 30, isLineVisible: false }} />
    </div>
  );
};

NotAllowedError.propTypes = {
  error: PropTypes.string,
  errorDescription: PropTypes.string,
};


const Submission = ({ loading, error, successMessage, successDescription, errorDescription}) => {
  return (
    <div className={styles.container}>
      {loading &&
        <Spinner />
      }
      {!loading && !error &&
        <>
          <div className={styles.primary}>
            <FontAwesomeIcon icon={faCheck} />
            <span>{successMessage}</span>
          </div>
          <div className={styles.secondary}>{successDescription}</div>
        </>
      }
      {!loading && error &&
        <>
          <div className={styles.primary}>
            <FontAwesomeIcon icon={faTimes} className={styles.error}/>
              {error !== true &&
                <span>{error}</span>
              }
          </div>
          <div className={styles.secondary}>{errorDescription}</div>
        </>
      }
      <EmptySpace desktop={{ margin: 60, isLineVisible: false }} mobile={{ margin: 30, isLineVisible: false }} />
    </div>
  );
};

export default Submission;

Submission.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  successMessage: PropTypes.string,
  successDescription: PropTypes.string,
  errorDescription: PropTypes.string,
};
